@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 98%;
		--foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--popover: 0 0% 98%;
		--popover-foreground: 222.2 47.4% 11.2%;

		--card: 0 0% 98%;
		--card-foreground: 222.2 47.4% 11.2%;

		--border: 214.3 31.8% 85.4%;
		--input: 214.3 31.8% 85.4%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 100% 50%;
		--destructive-foreground: 210 40% 98%;

		--success: 142 72% 44%;
		--success-foreground: 210 40% 98%;

		--warning: 38 92% 50%;
		--warning-foreground: 48 96% 89%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem;

		--nav: 222.2 47.4% 11.2%;
		--nav-foreground: 0 0% 98%;
	}

	.dark {
		--background: 224 71% 4%;
		--foreground: 213 31% 91%;

		--muted: 223 47% 11%;
		--muted-foreground: 215.4 16.3% 56.9%;

		--popover: 224 71% 4%;
		--popover-foreground: 215 20.2% 65.1%;

		--card: 224 71% 4%;
		--card-foreground: 213 31% 91%;

		--border: 216 34% 17%;
		--input: 216 34% 17%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 1.2%;

		--secondary: 222.2 47.4% 11.2%;
		--secondary-foreground: 210 40% 98%;

		--accent: 216 34% 17%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 63% 31%;
		--destructive-foreground: 210 40% 98%;

		--success: 130 63% 31%;
		--success-foreground: 210 40% 98%;

		--warning: 48 85% 60%;
		--warning-foreground: 224 71% 4%;

		--ring: 216 34% 17%;

		--radius: 0.5rem;

		--nav: 224 71% 6%;
		--nav-foreground: 0 0% 98%;
	}
	.grecaptcha-badge {
		visibility: hidden;
	}
}

@layer base {
	* {
		@apply border-border;
		font-family: "Inter", sans-serif;
	}
	body {
		@apply bg-background text-foreground;
		font-feature-settings: "rlig" 1, "calt" 1;
	}
}

.shrink {
	animation: A_shrink 3000ms linear normal forwards;
}

@keyframes A_shrink {
	100% {
		max-width: 0px;
	}
	0% {
		max-width: 100%;
	}
}

.rdp-tbody > tr:first-of-type {
	@apply justify-end;
}

@layer utilities {
	.scrollbar::-webkit-scrollbar {
		width: 8px;
		height: 5px;
	}

	.scrollbar::-webkit-scrollbar-thumb {
		background: #cccccc;
		border-radius: 100vh;
	}

	.scrollbar::-webkit-scrollbar-thumb:hover {
		background: #808080;
	}
}

input[type="time"]::-webkit-calendar-picker-indicator {
	filter: invert(48%) sepia(40%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}
